import { useEffect, useState } from "react";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import Pagenation from "../../Components/Utils/Pagenation";
// import styled from "@emotion/styled";
// import SettingsIcon from "@mui/icons-material/Settings";
import {
  get_e_invoice_list,
  get_e_invoice_submit_api,
  get_egs_list,
  get_invoice_download_api,
  get_invoice_validate_api,
} from "../../Api/EInvoice/EInvoiceApis";
import { InvoiceAutoComplete } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import ViewInvoice from "./EInvoiceComponents";
import FilterBtn from "../../Components/Utils/FilterBtn";
import ReportDownload from "../../Components/Utils/ReportDownload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViknDrawer from "../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../Components/Utils/VBDatePiker";
// import ViknSearchAutoComplete from "../../Components/Utils/ViknSearchAutoComplete";
import ViknCheckboxGroup from "../../Components/Utils/ViknCheckboxGroup";
import moment from "moment";
import ViknAutoComplete from "../../Components/Utils/ViknAutoComplete";
import { CallBranchsList } from "../../Api/Reports/CommonReportApi";
import SearchReport from "../../Components/Utils/SearchReport";

const EinvoiceInvoiceList = () => {
  const dispatch = useDispatch();

  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const { PriceDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const noOfItems = appSetting.itemPerPage;

  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
    isShowOnlyMainEntry: false,
    branch: null,
    search: "",
  });

  const [filterList, setFilterList] = useState({
    BranchList: [],
  });

  const [page_no, setPage_no] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);

  const [openView, setOpenView] = useState(false);
  const [SelectedInvoice, setSelectedInvoice] = useState(null);
  const [apiResult, setApiResult] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const openAction = Boolean(anchorElAction);

  const ClickViewInvoice = (id, InvoiceName, VoucherNumber) => {
    setSelectedInvoice({
      id: id,
      InvoiceName: InvoiceName,
      VoucherNumber: VoucherNumber,
    });
    setOpenView(true);
  };

  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    dataList: [],
    egsList: [],
    egs: null,
    selectedItems: [],
    type_of_egs: { name: "sandbox", value: 0 },
  });

  const handleClickAvtion = function (event) {
    setAnchorElAction(event.currentTarget);
  };

  // const radiosValue = [
  //   // "Zero Balance",
  //   "Creditor",
  //   "Debitor",
  // ].map((option) => {
  //   const isChecked = filterOptions[`is${option.replace(" ", "")}`];

  //   return {
  //     checked: isChecked !== undefined ? isChecked : false,
  //     onChange: () =>
  //       setFilterOptions({
  //         ...filterOptions,
  //         isCreditor: false,
  //         isDebitor: false,
  //         // isZeroBalance: false,
  //         [`is${option.replace(" ", "")}`]:
  //           !filterOptions[`is${option.replace(" ", "")}`],
  //       }),
  //     label: option,
  //   };
  // });

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  const onAutoChange = (e, v, name) => {
    if (v && name) {
      let value = v;
      setState({ ...state, [name]: value });
    }
  };

  const onInputChange = () => {
    //pass
  };

  const egs_list = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_of_egs: state?.type_of_egs.value,
    };
    let Data = [];
    let res = await get_egs_list({ ...payload });
    if (res.StatusCode === 6000) {
      Data = res.data;
    }

    return Data;
  };

  const get_invoice_list = async (uniq_id) => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      uniq_id: uniq_id,
      type_of_egs: state?.type_of_egs.value,
      page_number: page_no,
      items_per_page: 15,
      show_only_main_entry: filterOptions.isShowOnlyMainEntry,
      search: filterOptions.search,
      InvoiceName: "",
      invoice_status: "",
    };
    let Data = [];
    let res = await get_e_invoice_list({ ...payload });
    if (res) {
      if (res.StatusCode === 6000) {
        Data = res.data ?? [];
      }
    }

    return Data;
  };

  const read__xml = async (data) => {
    let egs_id = state.egs.id;
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id: egs_id,
      invoice_id: data.id,
      InvoiceName: data.InvoiceName,
      VoucherNumber: data.VoucherNumber,
      type_of_egs: state?.type_of_egs.value,
      is_download: true,
      // is_read:true
    };

    let res = await get_invoice_validate_api({ ...payload });
  };

  const download_xml = async (data) => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id: state.egs.id,
      invoice_id: data.id,
      InvoiceName: data.InvoiceName,
      VoucherNumber: data.VoucherNumber,
      type_of_egs: state?.type_of_egs.value,
      is_download: true,
    };

    let res = await get_invoice_download_api({ ...payload });

    if (res.status === 200) {
      // Check if Content-Disposition header exists
      const contentDisposition = res.headers["content-disposition"];
      let filename = "download.xml"; // Default filename

      if (contentDisposition && contentDisposition.includes("filename=")) {
        filename = contentDisposition
          .split("filename=")[1]
          .replace(/['"]/g, "");
      }

      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("Failed to download the file.");
    }
  };

  const filterData = async function () {
    const BranchResponse = await CallBranchsList({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    });
    let BranchList = [];
    if (BranchResponse && BranchResponse.StatusCode === 6000) {
      BranchList = BranchResponse.data;
      setFilterList({
        ...filterList,
        BranchList: BranchList,
      });
    }
  };

  const validate_xml = async (data) => {
    let egs_id = state.egs.id;
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id: egs_id,
      invoice_id: data.id,
      InvoiceName: data.InvoiceName,
      VoucherNumber: data.VoucherNumber,
      type_of_egs: state?.type_of_egs.value,
    };

    let res = await get_invoice_validate_api({ ...payload });

    if (res.StatusCode === 6000) {
      setApiResult(res.data);
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message ?? "Success",
          severity: "info",
        })
      );
    } else {
      setApiResult(res.data);
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
  };

  const submitInvoice = async (data, submit_type) => {
    let egs_id = state.egs.id;

    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id: egs_id,
      invoice_id: data.id,
      submit_type: submit_type,
      InvoiceName: data.InvoiceName,
      VoucherNumber: data.VoucherNumber,
      type_of_egs: state?.type_of_egs.value,
    };

    let res = await get_e_invoice_submit_api({ ...payload });

    if (res.StatusCode === 6000) {
      setApiResult(res.validationResults);
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message ?? "Success",
          severity: "info",
        })
      );
    } else {
      setApiResult(res.validationResults);
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
  };

  const handleDefaultFilter = function () {};

  const onFilter = function () {
    const fetchData = async () => {
      let egsList = await egs_list();
      let egs = egsList[0];
      let invoiceList = await get_invoice_list(egs.id);
      let dataList = invoiceList;
      setState({ ...state, egsList, egs: egs, dataList });
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      let egsList = await egs_list();
      let egs = egsList[0];
      let invoiceList = await get_invoice_list(egs.id);
      let dataList = invoiceList;
      setState({ ...state, egsList, egs: egs, dataList });
    };

    fetchData();
    filterData();
  }, []);

  console.log(state, "----staete");

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box
            className="left"
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {t("Zatca E-Invoice Report")}
            </Typography>
            <SearchReport
              placeholder={"Search"}
              value={filterOptions.search}
              onChange={(e) => {
                setFilterOptions({ ...filterOptions, search: e.target.value });
              }}
              onClick={() => {
                onFilter();
              }}
            />

            {/* <InvoiceAutoComplete
            name={"egs"}
            label={"Select a Egs"}
            optionLabel={"egs_name"}
            List={state.egsList}
            Value={state.egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChan
            ge}
          /> */}
            {/* <p style={{color:"#0A9EF3"}}>Egs Name : {state?.egs?.egs_name}</p> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />

            <InvoiceAutoComplete
              name={"type_of_egs"}
              label={"Select a type_of_egs"}
              optionLabel={"name"}
              List={[
                { name: "sandbox", value: 0 },
                { name: "simulation", value: 1 },
                { name: "production", value: 2 },
              ]}
              Value={state.type_of_egs}
              OnChange={onAutoChange}
              OnInputChange={onInputChange}
            />
          </Box>

          <Box
            className="right"
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            {true && (
              <ReportDownload
                download_type={"PDF"}
                // VoucherType={
                //   filterOptions.isCreditor
                //     ? "creditors"
                //     : filterOptions.isDebitor
                //     ? "debitors"
                //     : filterOptions.isZeroBalance
                //     ? "zero_balance"
                //     : null
                // }
                invoice_type={""}
                // showPrint={showPrint}
                // setPrint={setPrint}
                // RouteLedgers={[0, ...filterOptions.routs.map(e => e.RouteID)]}
                // ToDate={filterOptions.FromDate.format("YYYY-MM-DD")}
                // BranchID={CompanyDetails.BranchID}
                // UserID={UserDetails.user_id}
                PriceRounding={appSetting.PriceDecimalPoint}
                // PartyType={filterOptions.isCustomer?"customer":filterOptions.isSupplier?"supplier":"both"}
              />
            )}
            <Pagenation
              totalItem={state.data_count}
              page_no={page_no}
              setPage_no={setPage_no}
            />
            {/* <SettingsIcon/> */}
          </Box>
        </Box>

        <VBTableContainer>
          <VBTableHeader>
            {/* <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader> */}
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Serial No")}
            </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Supplier name")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Customer name")}</VBTableCellHeader>
            <VBTableCellHeader>{t("created date")}</VBTableCellHeader>
            <VBTableCellHeader>{t("invoice name")}</VBTableCellHeader>
            <VBTableCellHeader>{t("line extension amount")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Pay.amount")}</VBTableCellHeader>
            {/* <VBTableCellHeader>{t("xml")}</VBTableCellHeader> */}
            <VBTableCellHeader>{t("Status")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Action")}</VBTableCellHeader>
          </VBTableHeader>
          {/* 
        <TableBody>

          

        </TableBody> */}

          {loading ? (
            <VBSkeletonLoader />
          ) : (
            <TableBody>
              {state.dataList.map((data, i) => (
                // <TableRow
                // // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
                // //   onClick={()=>{handleTableRowClick(data)}}
                // >
                //   <VBTableCellBody
                //     sx={{
                //       // cursor: "pointer",
                //       textAlign: "left",
                //       padding: "0px",
                //       paddingLeft: "5px",
                //     }}
                //   >
                //     <Checkbox
                //       checked={
                //         state.selectedItems.includes(data.id) ? true : false
                //       } // The checked prop determines whether this checkbox is checked.
                //       onChange={() => {
                //         selectItems("not", data.id);
                //       }}
                //       sx={{
                //         padding: "2px",
                //         margin: 0,
                //         color: blue[400],
                //         "&.Mui-checked": {
                //           color: blue[400],
                //         },
                //         "& .MuiSvgIcon-root": { fontSize: "20px" },
                //       }}
                //     />
                //   </VBTableCellBody>
                //   <VBTableCellBody
                //     sx={{  cursor: "pointer" }}
                //   >
                //     {i+1}
                //   </VBTableCellBody>
                //   <VBTableCellBody sx={{  cursor: "pointer" }}>
                //     {data?.VoucherNumber}
                //   </VBTableCellBody>
                //   <VBTableCellBody sx={{ cursor: "pointer" }}>
                //     {data?.SupplierName}
                //   </VBTableCellBody>
                //   <VBTableCellBody sx={{ cursor: "pointer" }}>
                //     {data?.CustomerName}
                //   </VBTableCellBody>
                //   <VBTableCellBody
                //     className="truncated-text "
                //     sx={{ cursor: "pointer" }}
                //   >
                //     {data?.CreatedDate}
                //   </VBTableCellBody>
                //   <VBTableCellBody sx={{ cursor: "pointer" }}>
                //     {data?.InvoiceName}
                //   </VBTableCellBody>
                //   <VBTableCellBody sx={{  cursor: "pointer" }}>
                //     {data?.LineExtensionAmount}
                //   </VBTableCellBody>
                //   <VBTableCellBody sx={{  cursor: "pointer" }}>
                //     {data?.PayableAmount}
                //   </VBTableCellBody>
                //   <VBTableCellBody sx={{  cursor: "pointer" }}>
                //   <Button onClick={()=>{download_xml({id:data.id})}}>Download</Button>
                //   </VBTableCellBody>
                //   <VBTableCellBody
                //     sx={{  cursor: "pointer",color:data.Status===0?"blue":data.Status ===1 || data.Status ===4?"green":data.Status === 2 || data.Status ===6?"red":data.Status ===3 || data.Status ===5 ?"orange":"black" }}
                //   >
                //     {data.Status===0?"Not Submitted":data.Status ===1?"Success":data.Status === 2?"Failed":data.Status ===3 ?"Success With Warnings":data.Status ===4?"Resubmit and success":data.Status ===5?"resubmit and success with warnings":data.Status ===6?"resubmit and failed":"Unknown Status"}

                //   </VBTableCellBody>
                //   <VBTableCellBody
                //     sx={{  cursor: "pointer" }}
                //   >
                //     <Button onClick={()=> ClickViewInvoice(data.id,data?.InvoiceName,data?.VoucherNumber) }>View</Button>

                //   </VBTableCellBody>

                // </TableRow>

                <TableRow>
                  <VBTableCellBody>{i} </VBTableCellBody>
                  <VBTableCellBody>{data?.VoucherNumber}</VBTableCellBody>
                  <VBTableCellBody>{data?.SupplierName}</VBTableCellBody>
                  <VBTableCellBody>{data?.CustomerName}</VBTableCellBody>
                  <VBTableCellBody>{data?.CreatedDate}</VBTableCellBody>
                  <VBTableCellBody>{data?.InvoiceName}</VBTableCellBody>
                  <VBTableCellBody>{data?.LineExtensionAmount}</VBTableCellBody>
                  <VBTableCellBody>{data?.PayableAmount}</VBTableCellBody>
                  <VBTableCellBody
                    sx={{
                      color: "#048C09",
                      fontSize: "14px",
                      textTransform: "upper-case",
                    }}
                  >
                    {data.Status <= 6
                      ? {
                        0: "Not Submitted",
                        1: "Success",
                        2: "Failed",
                        3: "Success With Warnings",
                        4: "Resubmit and Success",
                        5: "Resubmit and Success with Warnings",
                        6: "Resubmit and Failed",
                      }[data.Status]
                      : "Unknown Status"
                    }
                  </VBTableCellBody>
                  <VBTableCellBody>
                    <IconButton
                      id="basic-button"
                      aria-controls={openAction ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openAction ? "true" : undefined}
                      onClick={handleClickAvtion}
                    >
                      <MoreVertIcon sx={{ color: "#0A9EF3" }} />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElAction}
                      open={openAction}
                      onClose={() => setAnchorElAction(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      sx={{
                        top: -10,
                        left: -53,
                        "& .MuiPaper-root": {
                          borderRadius: 2,
                          boxShadow: "none",
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setAnchorElAction(null);
                          setOpenView(true);

                          setSelectedInvoice({
                            id: data.id,
                            InvoiceName: data.InvoiceName,
                            VoucherNumber: data.VoucherNumber,
                          })

                        }}
                      >
                        View
                      </MenuItem>
                      <MenuItem onClick={() => setAnchorElAction(null)}>
                        Export
                      </MenuItem>
                    </Menu>
                  </VBTableCellBody>
                </TableRow>
              ))}
              <TableRow></TableRow>
              {/* <VBTableRowNull
              length={
                100
                // state.data_count ? state.data_count : state.dataList.length
              }
            /> */}
            </TableBody>
          )}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        onFilter={onFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.FromDate}
                toDate={filterOptions.FromDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                  })
                }
              />
            </ListItem>
            {/* <ListItem>
              <ViknCheckboxGroup 
                radios={radiosValue1}
              />
            </ListItem> */}
            <Divider />
            <ListItem sx={{ py: 2 }}>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: "Show only Main Entry",
                    checked: filterOptions.isShowOnlyMainEntry,
                    onChange: () => {
                      setFilterOptions({
                        ...filterOptions,
                        isShowOnlyMainEntry: !filterOptions.isShowOnlyMainEntry,
                      });
                    },
                  },
                ]}
              />
            </ListItem>
            <Divider />
            <ListItem sx={{ py: 2 }}>
              <ViknAutoComplete
                label={"Status"}
                placeholder={"Select Branch"}
                options={filterList.BranchList}
                value={filterOptions.branch}
                getOptionLabel={(option) => option.BranchName || ""}
                onChange={(e, v) => {
                  setFilterOptions({
                    ...filterOptions,
                    branch: v,
                  });
                }}
              />
            </ListItem>

            <Divider />

            <ListItem sx={{ py: 2 }}>
              <ViknAutoComplete
                label={"Type"}
                placeholder={"Select User"}
                options={[]}
              />
            </ListItem>

            <Divider />
          </List>
        }
      />
      <ViewInvoice
        open={openView}
        setOpen={setOpenView}
        SelectedInvoice={SelectedInvoice}
        submitInvoice={submitInvoice}
        apiResult={apiResult}
        validate_xml={validate_xml}
      />
    </>
  );
};

export default EinvoiceInvoiceList;

// const FlexBox = styled(Box)(() => ({
//   display: "flex",
//   alignItems: "center",
//   width: "39%",
//   "&.right": {
//     justifyContent: "flex-end",
//   },
//   "&.left": {
//     justifyContent: "flex-start",
//     gap: "22px",
//   },
// }));

import { store } from "./app/store";
// export const URL = "https://www.api.viknbooks.com/";
// export const BASE_URL = "https://www.api.viknbooks.com/api/v10/";
// export const BASE_V11_URL = "https://www.api.viknbooks.com/api/v11/";
// export const MEDIA_URL = "https://www.api.viknbooks.com";


let state = store.getState()
export const URL = state.companyUrl.companyUrl+"/";
export const BASE_URL = state.companyUrl.companyUrl+"/api/v10/";
export const BASE_V11_URL = state.companyUrl.companyUrl+"/api/v11/";
export const MEDIA_URL = state.companyUrl.companyUrl;

// export const ACCOUNTS_API_URL = "http://localhost:8000/";
// export const ACCOUNTS_API_MEDIA_URL = "http://localhost:8000";
// export const ACCOUNTS_API_BaseURL = "http://localhost:8000/api/v1/";
// export const ACCOUNTS_FRONT_URL = "http://localhost:3000/";
// export const domain = "localhost";
// export const ADMIN_FRONT_URL = "http://localhost:3001/";
// export const TASK_FRONT_URL = "http://localhost:3003/";
// export const VIKNBOOKS_FRONT_URL = "http://localhost:3002/";
// export const PAYROLL_FRONT_URL = "http://localhost:3001/";
// export const Report_URL = "http://localhost:3001/";

// Get the base URL using JavaScript
export const MyUrl = (window.location.protocol + '//' + window.location.host).replace("reports.", "");

// export const URL = "https://www.api.viknbooks.in/";
// export const BASE_V11_URL = "https://www.api.viknbooks.in/api/v11/";
// export const BASE_URL = "https://www.api.viknbooks.in/api/v10/";
// export const MEDIA_URL = "https://www.api.viknbooks.in";

// export const ACCOUNTS_API_URL = "https://api.accounts.vikncodes.in/";
// export const ACCOUNTS_API_MEDIA_URL = "https://api.accounts.vikncodes.in";
// export const ACCOUNTS_API_BaseURL = "https://api.accounts.vikncodes.in/api/v1/";
// export const ACCOUNTS_FRONT_URL = "https://accounts.vikncodes.in/";
// export const domain = "viknbooks.in";
// export const ADMIN_FRONT_URL = "https://admin.vikncodes.in/";
// export const TASK_FRONT_URL = "https://task.vikncodes.in/";
// export const VIKNBOOKS_FRONT_URL = "https://www.viknbooks.in/";
// export const PAYROLL_FRONT_URL = "payroll.vikncodes.in";
// export const Report_URL = "https://reports.viknbooks.in/";


// export const URL = "https://www.api.viknbooks.com/";
// export const BASE_URL = "https://www.api.viknbooks.com/api/v10/";
// export const BASE_V11_URL = "https://www.api.viknbooks.com/api/v11/";
// export const MEDIA_URL = "https://www.api.viknbooks.com";

export const ACCOUNTS_API_URL = "https://api.accounts.vikncodes.com/";
export const ACCOUNTS_API_MEDIA_URL = "https://api.accounts.vikncodes.com";
export const ACCOUNTS_API_BaseURL = "https://api.accounts.vikncodes.com/api/v1/";
export const ACCOUNTS_FRONT_URL = "https://accounts.vikncodes.com/";
export const domain = "viknbooks.com";
export const ADMIN_FRONT_URL = "https://admin.vikncodes.com/";
export const TASK_FRONT_URL = "https://task.vikncodes.com/";
export const VIKNBOOKS_FRONT_URL = "https://viknbooks.com/";
export const PAYROLL_FRONT_URL = "payroll.vikncodes.com";
export const Report_URL = "https://reports.viknbooks.com/";





import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";
// import { store } from "../../app/store";

// const state = store.getState();
// const { Country } = state.branchSettings;

export const get_VoucherNo = async function (body,dispatch) {

    try {
      const { data } = await axios.post("main/generate/voucherNo/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on get_VoucherNo API", error);
      dispatch(
        openSnackbar({
          open: true,
          message: error.response?.data?.message || "Error Occurred In API",
          severity: "error",
        })
      );
      return error
    }
  };
  
  
  
  export const ledger_list_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/accountLedgers/ledger_list/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};

export const ledger_details_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/accountLedgers/get-ledger-details/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
  

export const place_of_supply_api = async function (id) {

  try {
    const { data } = await axios.get("api/v10/states/country-states/" + id +"/",);
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
  

export const product_search_api = async function (body) {

  try {
    const { data } = await axios.post("api/v10/products/products-search-qry/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const product_search_new_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/products/products-search-qry/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
  


//for New sales - Don't change or use without asking (sahil)
export const get_pricelist_list_new_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/priceLists/get-pricelist-list-new/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
  

//for New sales - Don't change or use without asking (sahil)
export const get_single_pricelist_new_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/priceLists/single-priceList-new/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
  
//for New sales - Don't change or use without asking (sahil)
export const get_product_by_barcode_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/products/products-by-barcode/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


//formset
export const get_formset_values_api = async function (body) {

  try {
    const { data } = await axios.post("main/formset/values/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const get_stock_of_products_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/products/get-stocks-of-products/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const get_product_history_api = async function (body) {

  try {
    const { data } = await axios.post("api/v10/sales/customer-product/sales-history/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const billwise_datas = async function (body) {

  try {
    const { data } = await axios.post("api/v10/payments/billwise-list/customer/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const list_countries = async function () {

  try {
    const { data } = await axios.get("api/v10/countries/countries/");
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const list_states = async function (id) {

  try {
    const { data } = await axios.get("api/v10/states/country-states/"+id+"/");
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const price_category = async function (body) {
  try {
    const { data } = await axios.post("api/v10/priceCategories/priceCategories/",{
      ...body
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};
import CreateCustomer from "../../Pages/Contacts/CreateCustomer/CreateCustomer";
import CreateSupplier from "../../Pages/Contacts/CreateSupplier/CreateSupplier";

const CustomerRoute = [
  {
    path: "create-customer",
    element: <CreateCustomer />,
    isAuth: true,
  },
  {
    path: "create-supplier",
    element: <CreateSupplier />,
    isAuth: true,
  },
];

export default CustomerRoute;

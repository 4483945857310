import { customRound } from "../../generalFunction";

function roundOffNumber(value, decimalPlaces) {

  const roundedValue = Math.round(value,2);
  
  return roundedValue
}

// function customRound(x) {
//   // let rounded = Math.round(Number(x) * 100) / 100;

//   // // Check if the difference between the number and its rounded value
//   // // If the difference is close to .5, round up to the nearest tenth
//   // if (Math.abs(rounded - x) === 0.05) {
//   //     return Math.round(rounded * 10) / 10;
//   // }

//   // return rounded;

// }


// function customRound(x) {
//   let num = parseFloat(Number(x).toFixed(2));
//   return num;
// }


// function customRound(x) {
//   let num = parseFloat(x);
//   let strNum = num.toFixed(3); // Keep three decimals for intermediate calculation
//   let thirdDigit = parseInt(strNum[strNum.length - 1], 10); // Get the third decimal digit

//   if (thirdDigit >= 5) {
//     // If the third digit is 5 or more, round up the second digit
//     return parseFloat((num + 0.001).toFixed(2));
//   } else {
//     // Otherwise, just truncate
//     return parseFloat(num.toFixed(2));
//   }
// }


// function customRound(x) {
//   const rounded = Math.round(x * 100) / 100;

//   if (rounded - x === 0.5) {
//     return rounded;
//   }

//   return rounded;
// }


//Calculation of Total Amounts
export function SalesInvoiceCalc (dataState,state,DataList,is_manual_roundoff,customRound) {
    let TotalTaxableAmount = 0
    let TotalGrossAmt = 0
    let TotalDiscount = 0
    let TotalTax = 0
    let NetTotal = 0
    let AdditionalCost = 0
    let GrandTotal = 0
    let RoundOff = customRound(state.RoundOff) || 0
    let CashReceived = 0
    let CashAmount = 0
    let BankAmount = 0
    let BillDiscPercent = customRound(state.BillDiscPercent) || 0
    let BillDiscAmt = customRound(state.BillDiscAmt) || 0
    let VATAmount = 0
    let SGSTAmount = 0
    let CGSTAmount = 0
    let IGSTAmount = 0
    let Balance = 0
    let OldLedgerBalance = 0
    let ShippingCharge = customRound(state.ShippingCharge) || 0
    let shipping_tax_amount = customRound(state.shipping_tax_amount) || 0
    let ShippingTax = state.ShippingTax
    if (ShippingTax){
      shipping_tax_amount = customRound(ShippingTax.SalesTax * ShippingCharge/100)
    }
    
    let TaxTaxableAmount = 0
    let NonTaxTaxableAmount = 0
    
    let DetailsAmountTotal = 0
    
    let DataListLength = DataList.filter((i)=> i.Product != null)?.length??0
    let TotalQty = customRound(DataListLength)
    
    // let BillDiscAmt_Split = 0
    // if (BillDiscAmt && TotalQty){
    //   BillDiscAmt_Split = customRound(BillDiscAmt/TotalQty)
    // }
    
    let TotalGrossAmount_for_billdisc = DataList.reduce((acc,curr) => acc + Number(curr.GrossAmount),0)
    TotalGrossAmount_for_billdisc = customRound(TotalGrossAmount_for_billdisc) || 0
    console.log(TotalGrossAmount_for_billdisc,"TotalGrossAmount_for_billdisc");
    
    let BillDisc_taxAmount = customRound(BillDiscAmt*15/100)
    //Looping dataList
    for (let index = 0; index < DataList.length; index++) {
        
        const item = DataList[index];
        
        let GrossAmount = customRound(item.GrossAmount) || 0
        let DiscountAmount = customRound(item.DiscountAmount) || 0
        console.log(item.TaxAmount,"-----------------------------");
        
        let TaxAmount = customRound(item.TaxAmount) || 0
        console.log(item.TaxAmount);
        
        let Qty = Number(item.Qty) || 0
        let Tax = item.Tax
        let SalesTax = customRound(Tax?.SalesTax??0)
        let Amount = customRound(item.Amount) || 0
    
        TotalGrossAmt += GrossAmount || 0
        TotalDiscount += DiscountAmount || 0
        TotalTaxableAmount += GrossAmount - DiscountAmount
        
        if (TaxAmount > 0){
            TaxTaxableAmount += GrossAmount - DiscountAmount
        }
        else{
            NonTaxTaxableAmount += GrossAmount - DiscountAmount
        }   

        // let BillDiscAmt_Split = customRound((GrossAmount/TotalGrossAmount_for_billdisc)*BillDiscAmt) || 0
        
      
        //bill disc amount is splitting equally to every details then substraction that with gross amount then finding total tax
        // TotalTax += (GrossAmount - (DiscountAmount + BillDiscAmt_Split)) + TaxAmount //* SalesTax / 100;
        TotalTax +=  TaxAmount //* SalesTax / 100;

        DetailsAmountTotal += Amount
   
        
    }
    TotalTax = TotalTax-BillDisc_taxAmount
    
    //after
    TotalDiscount += BillDiscAmt
    
    GrandTotal += customRound(TaxTaxableAmount + NonTaxTaxableAmount)
    GrandTotal += customRound(TotalTax)
    NetTotal += customRound(DetailsAmountTotal + ShippingCharge) || 0
    GrandTotal += customRound(ShippingCharge + shipping_tax_amount)
    GrandTotal -= BillDiscAmt
    
    
    if (is_manual_roundoff === false){
      let roundedValue= roundOffNumber(GrandTotal, 2);
      RoundOff = customRound(roundedValue - GrandTotal)
      GrandTotal = roundedValue
    }
    else{
      GrandTotal += RoundOff
    }
    

  state.BillDiscAmt = BillDiscAmt
  // Update the state
  state.shipping_tax_amount = customRound(shipping_tax_amount)
  state.TotalQty = TotalQty
  state.TotalGrossAmt = customRound(TotalGrossAmt)
  state.TotalDiscount = customRound(TotalDiscount)
  state.TotalTax = customRound(TotalTax)
  state.NetTotal = customRound(NetTotal)
  state.TotalTaxableAmount = customRound(TotalTaxableAmount)
  state.TaxTaxableAmount = customRound(TaxTaxableAmount)
  state.NonTaxTaxableAmount = customRound(NonTaxTaxableAmount)
  state.RoundOff = customRound(RoundOff)
  state.GrandTotal = customRound(GrandTotal)
  
  
  return state
    
}

//Calculation of an line
export function SalesLineCalc (dataState,state,lineItem,customRound){

    let Qty = Number(lineItem.Qty) || 0
    let Rate = customRound(lineItem.Rate) || 0
    let DiscountAmount = customRound(lineItem.DiscountAmount) || 0
    let Tax = lineItem.Tax 
    let TaxID = lineItem.TaxID
    let SalesTax = customRound(Tax?.SalesTax??0)

    
    let UnitTaxAmount = customRound((Rate/100) * SalesTax)
    let InclusivePrice =customRound( Rate + UnitTaxAmount )
    let GrossAmount = customRound(Rate * Qty)

    let TaxableAmount = customRound(GrossAmount - DiscountAmount)
    let TaxAmount = customRound(GrossAmount - DiscountAmount)
    TaxAmount = customRound((TaxAmount/100) * SalesTax)
    let Amount = (GrossAmount - DiscountAmount) + TaxAmount
    Amount = customRound(Amount)
    //update item
    lineItem.Qty = Qty
    lineItem.Rate = customRound(lineItem.Rate) || 0
    lineItem.DiscountAmount = DiscountAmount
    
    lineItem.TaxableAmount = customRound(TaxableAmount)
    lineItem.TaxAmount = customRound(TaxAmount)
    lineItem.InclusivePrice = customRound(InclusivePrice)
    lineItem.GrossAmount = customRound(GrossAmount)
    lineItem.Amount = customRound(Amount)

    
    return lineItem
    
}


//Validation 
export const Sales_Validation = (dataState,state,DataList,GeneralSettingsData) => {
  
    let field = null;
    let message = null;
    let error = null;
    let indexList = [];

    let Data = DataList;
    let length = Data.length;
    
    let is_non_taxable_amount = false
    let is_taxable_amount = false

    
    //check is there atlease one valid row
    if (Data.length === 0) {
        error = true;
        message = "At least need one valid row";
    } 
    else if (Data.length === 1 && !Data[0].Product) {
        error = true;
        message = "At least need one valid row";
    }

    //looping the dataList and checking all mandatory values are included
    Data.map((obj, i) => {
 
        if (!obj.Product) {
          if (
            i + 1 === length &&
            !obj.ProductCode &&
            !obj.BarCode &&
            !obj.Product &&
            // !obj.Description &&
            !obj.Unit &&
            // !obj.UnitList &&
            !obj.Stock &&
            !obj.Qty &&
            obj.Qty <= 0 &&
            // !obj.FQty &&
            !obj.Rate &&
            // !obj.AvgCost &&
            !obj.Tax &&
            !obj.InclusivePrice &&
            !obj.GrossAmount &&
            // !obj.DiscPerc &&
            !obj.DiscountAmount &&
            !obj.TaxAmount &&
            !obj.Amount &&
            !obj.MRP 
            // !obj.PurchasePrice &&
            // !obj.BatchCode &&
            // !obj.MinimumSalesPrice &&
            // !obj.AverageCost 
          ) {
            //ignoring last line if product is not selected and changed any other data in that line
            console.log("IGNORE THE PART");
          } else {
            indexList.push(i);
          }
        } else if (!obj.Product) {
          indexList.push(i);
        }
        else if (!obj.Unit) {
            indexList.push(i);
          }
          else if (!obj.Qty && Number(obj.Qty) <= 0) {
            indexList.push(i);
          }
          else if (!obj.Rate && Number(obj.Rate)<= 0 ) {
            indexList.push(i);
          }
          else if (!obj.Tax) {
            indexList.push(i);
          }
          else if (!obj.InclusivePrice) {
            indexList.push(i);
          }
          else if (!obj.GrossAmount) {
            indexList.push(i);
          }
          else if (!obj.TaxAmount && obj?.Tax?.TaxName !== "None" ) {
            indexList.push(i);
          }
          else if (!obj.Amount) {
            indexList.push(i);
          }
          else if ((Number(obj.DiscountPerc || 0) > GeneralSettingsData.sales_discount_limit) && GeneralSettingsData.sales_discount_perm){
            error = true;
            indexList.push(i);
            // message = `Your Bill discount is more than your limit, your limit is ${Number(GeneralSettingsData.sales_discount_limit).toFixed(1)}%`;
          }
          else if (GeneralSettingsData.EnableProductBatchWise === true && !obj.BatchCode){
            error = true;
            indexList.push(i);
          }

          
          
          
          if (obj.Product){
          //checking is there any non tax amount and tax amount
          if (obj.Tax.TaxName === "None"){
            is_non_taxable_amount = true
          }
          else{
            is_taxable_amount = true
          }
          }
          
    });
    
    
    //doing this becouse index is starting from 0 but lines are starting from 1 ,so just incrementing 1 to every index
    let newIndexList = indexList.map(i => i + 1);
    
    if (indexList.length > 0) {
      message = "Data missing in Lines " + newIndexList;
      error = true;
    }

    
    
    //checking values of dataState
    
    //checking values of state
    if (!state.AccountLedger && !error) {
        error = true;
        message = "Provide valid AccountLedger";
        } else if (!state.CashAccount && !error) {
        error = true;
        message = "Provide valid CashAccount";
        } else if (!state.BankAccount && !error) {
        error = true;
        message = "Provide valid BankAccount";
        } else if (!state.Employee && GeneralSettingsData.EnableSalesManInSales && !error) {
        error = true;
        message = "Provide valid Sales Man";
        }
        else if (!state.PriceCategory && !error) {
        error = true;
        message = "Provide valid PriceCategory";
        }
        else if (!state.Warehouse && !error) {
            error = true;
            message = "Provide valid Warehouse";
        }
        else if (!state.Treatment && !error) {
            error = true;
            message = "Provide valid Treatment";
        }
        else if (!state.PlaceOfSupply && !error) {
            error = true;
            message = "Provide valid PlaceOfSupply";
        }
        else if (!state.Date && !error) {
            error = true;
            message = "Provide valid Date";
        }
        else if (!state.TotalGrossAmt && !error) {
            error = true;
            message = "Provide valid TotalGrossAmt";
        }
        else if (!state.TotalTax && !error && is_taxable_amount) {
            error = true;
            message = "Provide valid TotalTax";
        }

        else if (!state.NetTotal && !error) {
            error = true;
            message = "Provide valid NetTotal";
        }

        else if (!state.TotalTaxableAmount && !error) {
            error = true;
            message = "Provide valid TotalTaxableAmount";
        }
        else if (!state.TaxTaxableAmount && !error && is_taxable_amount) {
            error = true;
            message = "Provide valid TaxTaxableAmount";
        }
        else if (!state.NonTaxTaxableAmount && !error && is_non_taxable_amount) {
            error = true;
            message = "Provide valid NonTaxTaxableAmount";
        }

        else if (!state.GrandTotal && !error) {
            error = true;
            message = "Provide valid GrandTotal";
        }
        else if ( state.BankAmount > state.GrandTotal && !error) {
          error = true;
          message = "Bank Amount can't  be more than grand total";
      }
        else if ( state.TotalDiscount > 0 && GeneralSettingsData.sales_discount_perm === false && !error) {
          error = true;
          message = "Sorry, you don't have permission for discount";
      }
      
      
        else if ((state.BillDiscPercent > GeneralSettingsData.sales_discount_limit) ){
        error = true;
          message = `Your Bill discount is more than your limit, your limit is ${Number(GeneralSettingsData.sales_discount_limit).toFixed(1)}%`;
      }

      else if (GeneralSettingsData.sales_save === false){
        error = true;
        message = "Sorry, you don't have permission for create an sales invoice "
      }


      
    return { error, message, field, indexList };
  };

import { useEffect, useState } from "react";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import {
  Box,
  Button,
  Checkbox,
  Paper,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import Pagenation from "../../Components/Utils/Pagenation";
import styled from "@emotion/styled";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  get_e_invoice_list,
  get_e_invoice_submit_api,
  get_egs_list,
  get_invoice_download_api,
  get_invoice_validate_api,
} from "../../Api/EInvoice/EInvoiceApis";
import { InvoiceAutoComplete } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
// import ViewInvoice from "./EinvoiceComp";
import ViewInvoice from "./EInvoiceComponents";
const EinvoiceInvoiceList = () => {
  const dispatch = useDispatch();
  
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const noOfItems = appSetting.itemPerPage;

  const [page_no, setPage_no] = useState(1);
  
  const [openView,setOpenView] = useState(false)
  const [SelectedInvoice,setSelectedInvoice] = useState(null)
  const [apiResult,setApiResult] = useState(null)

  

  const [t, i18n] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [state, setState] = useState({
    dataList: [],
    egsList: [],
    egs: null,
    selectedItems: [],
    type_of_egs:{name:"sandbox",value:0},
    count:0,
    // singleView:{}
  });
  
  const ClickViewInvoice = async(id,InvoiceName,VoucherNumber,transaction_id) =>{
    console.log(state);
    let invoiceList = await get_invoice_list(state.egs.id,transaction_id,true);
    console.log(invoiceList.Data,"=========================");
    
    // let dataList = invoiceList.Data;
    // setState({ ...state, egsList, egs: egs, dataList,count:invoiceList.count });
    setSelectedInvoice({id:id,InvoiceName:InvoiceName,VoucherNumber:VoucherNumber,singleView:invoiceList.Data})
    let correctedResponse = invoiceList.Data.full_response?.replace(/'/g, '"');
    try {
      setApiResult(correctedResponse ? JSON.parse(correctedResponse) : "");
    } catch (error) {
      console.error("JSON parsing error:", error);
      setApiResult(correctedResponse); // Handle the error case
    }
    
    setOpenView(true)
  }
  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  const onAutoChange = (e, v, name) => {
    if(v && name){
      let value = v
      setState({...state,[name]:value})
    }
  };

  const onInputChange = () => {
    //pass
  };

  const egs_list = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_of_egs:state?.type_of_egs.value
    };
    let Data = [];
    let res = await get_egs_list({ ...payload });
    if (res.StatusCode === 6000) {
      Data = res.data;
    }

    return Data;
  };

  const get_invoice_list = async (uniq_id,transaction_id="",IsView=false) => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      uniq_id: uniq_id,
      type_of_egs:state?.type_of_egs.value,
      page_number:page_no,
      items_per_page:15,
      IsView:IsView,
      id:transaction_id,
    };
    let Data = [];
    let count = state.count;
    let res = await get_e_invoice_list({ ...payload });
    if (res){
      
      if (res.StatusCode === 6000) {
        Data = res.data ?? [];
        count = res.count ?? [];
      }
    }

    return {Data,count};
  };
  
  
  
  const read__xml = async(data) => {
    let egs_id = state.egs.id
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id:egs_id,
      invoice_id:data.id,
      InvoiceName:data.InvoiceName,
      VoucherNumber:data.VoucherNumber,
      type_of_egs:state?.type_of_egs.value,
      is_download:true
      // is_read:true
    }
    
    
    let res = await get_invoice_validate_api({...payload});
  }
  
  


  const download_xml = async (data) => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id: state.egs.id,
      invoice_id: data.id,
      InvoiceName: data.InvoiceName,
      VoucherNumber: data.VoucherNumber,
      type_of_egs: state?.type_of_egs.value,
      is_download: true,
    };
  
    let res = await get_invoice_download_api({...payload});
  
    if (res.status === 200) {
      // Check if Content-Disposition header exists
      const contentDisposition = res.headers['content-disposition'];
      let filename = 'download.xml'; // Default filename
  
      if (contentDisposition && contentDisposition.includes('filename=')) {
        filename = contentDisposition.split('filename=')[1].replace(/['"]/g, '');
      }
  
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log('Failed to download the file.');
    }
  };
  
  
  
  
  
  
  const validate_xml = async(data) =>{
    setLoadingView(true)
    let egs_id = state.egs.id
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id:egs_id,
      invoice_id:data.id,
      InvoiceName:data.InvoiceName,
      VoucherNumber:data.VoucherNumber,
      type_of_egs:state?.type_of_egs.value
    }
    
    
    let res = await get_invoice_validate_api({...payload});
    
    
    if (res.StatusCode === 6000){
      setApiResult(res.data)
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message??"Success",
          severity: "info",
        })
      );
    }
    else{
      setApiResult(res.data)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
    setLoadingView(false)
    
  }
  
  
  const submitInvoice = async(data,submit_type) => {
    setLoadingView(true)
    let egs_id = state.egs.id
    
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id:egs_id,
      invoice_id:data.id,
      submit_type:submit_type,
      InvoiceName:data.InvoiceName,
      VoucherNumber:data.VoucherNumber,
      type_of_egs:state?.type_of_egs.value
    }
    
    let res = await get_e_invoice_submit_api({...payload});
    
    
    if (res.StatusCode === 6000){
      setApiResult(res.validationResults)
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message??"Success",
          severity: "info",
        })
      );
    }
    else{
      setApiResult(res.validationResults)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
    setLoadingView(false)
    
  }
  

  useEffect(() => {
    const fetchData = async () => {
      let egsList = await egs_list();
      let egs = egsList[0];
      let invoiceList = await get_invoice_list(egs.id);
      let dataList = invoiceList.Data;
      setState({ ...state, egsList, egs: egs, dataList,count:invoiceList.count });
    };

    fetchData();
  }, [page_no]);
  
  
  console.log(state,"----staete");
  

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
        }}
      >
        <FlexBox className="left">
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              mr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            {t("Zatca E-Invoice Report")}
          </Typography>

          {/* <InvoiceAutoComplete
            name={"egs"}
            label={"Select a Egs"}
            optionLabel={"egs_name"}
            List={state.egsList}
            Value={state.egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChan
            ge}
          /> */}
          <p style={{color:"#0A9EF3"}}>Egs Name : {state?.egs?.egs_name}</p>
          
          
          <InvoiceAutoComplete
            name={"type_of_egs"}
            label={"Select a type_of_egs"}
            optionLabel={"name"}
            List={[{name:"sandbox",value:0},{name:"simulation",value:1},{name:"production",value:2}]}
            Value={state.type_of_egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChange}
          />
          
        </FlexBox>

        <FlexBox className="right">
          <Pagenation
            totalItem={state.count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
          {/* <SettingsIcon/> */}
        </FlexBox>
      </Box>

      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader>{t("VoucherNumber")}</VBTableCellHeader>
          <VBTableCellHeader>{t("SupplierName")} </VBTableCellHeader>
          <VBTableCellHeader>{t("CustomerName")}</VBTableCellHeader>
          <VBTableCellHeader>{t("CreatedDate")}</VBTableCellHeader>
          <VBTableCellHeader>{t("InvoiceName")}</VBTableCellHeader>
          <VBTableCellHeader >{t("LineExtensionAmount")}</VBTableCellHeader>
          <VBTableCellHeader >{t("PayableAmount")}</VBTableCellHeader>
          <VBTableCellHeader>{t("xml")}</VBTableCellHeader>
          <VBTableCellHeader>{t("Status")} </VBTableCellHeader>
          <VBTableCellHeader >
              Action
          </VBTableCellHeader>
        </VBTableHeader>

        {loading ? (
          <VBSkeletonLoader />
        ) : (
          <TableBody>
            {state.dataList.map((data, i) => (
              <TableRow
              // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
              //   onClick={()=>{handleTableRowClick(data)}}
              >
                <VBTableCellBody
                  sx={{
                    // cursor: "pointer",
                    textAlign: "left",
                    padding: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <Checkbox
                    checked={
                      state.selectedItems.includes(data.id) ? true : false
                    } // The checked prop determines whether this checkbox is checked.
                    onChange={() => {
                      selectItems("not", data.id);
                    }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{  cursor: "pointer" }}
                >
                   {(page_no - 1) * 15 + i + 1}
                </VBTableCellBody>
                <VBTableCellBody sx={{  cursor: "pointer" }}>
                  {data?.VoucherNumber}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.SupplierName}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.CustomerName}
                </VBTableCellBody>
                <VBTableCellBody
                  className="truncated-text "
                  sx={{ cursor: "pointer" }}
                >
                  {data?.CreatedDate}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.InvoiceName}
                </VBTableCellBody>
                <VBTableCellBody sx={{  cursor: "pointer" }}>
                  {data?.LineExtensionAmount}
                </VBTableCellBody>
                <VBTableCellBody sx={{  cursor: "pointer" }}>
                  {data?.PayableAmount}
                </VBTableCellBody>
                <VBTableCellBody sx={{  cursor: "pointer" }}>
                <Button onClick={()=>{download_xml({id:data.id})}}>Download</Button>
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{  cursor: "pointer",color:data.Status===0?"blue":data.Status ===1 || data.Status ==4?"green":data.Status === 2 || data.Status ==6?"red":data.Status ===3 || data.Status ==5 ?"orange":"black" }}
                >
                  {data.Status===0?"Not Submitted":data.Status ===1?"Success":data.Status === 2?"Failed":data.Status ===3 ?"Success With Warnings":data.Status ==4?"Resubmit and success":data.Status ==5?"resubmit and success with warnings":data.Status ==6?"resubmit and failed":"Unknown Status"}
                  
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{  cursor: "pointer" }}
                >
                  <Button onClick={()=> ClickViewInvoice(data.id,data?.InvoiceName,data?.VoucherNumber,data.transaction_id) }>View</Button>
                  
                </VBTableCellBody>
 
              </TableRow>
            ))}
            <VBTableRowNull
              length={
                100
                // state.data_count ? state.data_count : state.dataList.length
              }
            />
          </TableBody>
        )}
      </VBTableContainer>
      
      
      <ViewInvoice open = {openView} setOpen={setOpenView} 
      SelectedInvoice={SelectedInvoice} submitInvoice={submitInvoice} 
      apiResult = {apiResult} validate_xml={validate_xml} 
      invoice_status={SelectedInvoice?.singleView?.invoice_status}
      loadingView={loadingView}
      />
      
      
    </Paper>
  );
};

export default EinvoiceInvoiceList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

 import { Navigate, useRoutes } from 'react-router-dom';
import PageNotFound from '../Pages/PageNotFound/PageNotFound';
import { Home } from '@mui/icons-material';
import ReportRoute from './reportsRoute/ReportRoute';
import inventoryRoutes from './InventoryRoutes/inventoryRoutes';
import { ACCOUNTS_FRONT_URL } from '../generalVeriable';
import salesRoutes from './SalesRoutes/SalesRouter';
import einvoiceRoutes from './EInvoiceRouter/EinvoiceRouter';
import purchaseRoutes from './PurchaseRoutes/PurchaseRoutes';
import ContactRoute from './ContactsRoute/ContactsRoute';

// Mock authentication functions
const isAuthenticated = () => {
  // Replace with real authentication logic
  function checkCookie(name) {
    const cookie = document.cookie.split(';').find(c => c.trim().startsWith(name + '='));
    return cookie !== undefined;
  }
  
  const isCookieExist = checkCookie('VBID');
  console.log(isCookieExist); // true if 'myCookie' exists, false otherwise
  
  return isCookieExist;

  // return true
};

// Higher Order Component for protected routes
const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // If not authenticated, redirect to login
    // return <Navigate to="/login" />;
    window.open(`${ACCOUNTS_FRONT_URL}signin?service=viknbooks`, "_self");
  } else {
    // If authenticated and passed second level authentication, render children
    return children;
  }
};

function VBRouter() {

  const protectedRoutes = [
    ReportRoute,
    inventoryRoutes,
    salesRoutes,
    einvoiceRoutes,
    purchaseRoutes,    
    ...ContactRoute,

  ];

  const routes = [
    // { path: "/", element: <Home />, index: true },
    ...protectedRoutes.map(route => ({
      ...route,
      element: <ProtectedRoute>{route.element}</ProtectedRoute>
    })),
    { path: "*", element: <PageNotFound /> }
  ];

  const routing = useRoutes(routes);

  return routing;
}

export default VBRouter;

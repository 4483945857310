
import axios from "axios";
export const EinvoiceURL = "http://localhost:8003/";






export const get_egs_list = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/eInvoice/egs-api/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const get_e_invoice_list = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/eInvoice/invoice-list/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
  };
  
  export const get_e_invoice_submit_api = async function (body) {

    try {
      const { data } = await axios.post("api/v11/eInvoice/invoice-submit-api/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
};
  


export const get_invoice_validate_api = async function (body) {

  try {
    const { data } = await axios.post("api/v11/eInvoice/invoice-validate-api/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};


export const get_invoice_download_api = async function (body) {
  try {
    const response = await axios.post(
      "api/v11/eInvoice/invoice-validate-api/",
      {...body},
      {
        responseType: 'blob', // This ensures the response is treated as a binary file (blob)
      }
    );
    return response;
  } catch (error) {
    console.log("Error on API", error);
    return error;
  }
};


import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";
import { store } from "../../app/store";

const state = store.getState();
const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;

const callCreateCustomer = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v11/parties/create-party/",
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const callCreateSupplier = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v11/parties/create-party/",
      data: body,
      headers: { "Content-Type": "multipart/form-data" },
    });
    
    return data
  } catch (error) {
    console.log("error on Salesorder API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

export { callCreateCustomer, callCreateSupplier };

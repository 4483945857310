import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import VBSwitch from "../../../Components/Utils/VBSwitch";
import VBInputField from "../../../Components/Utils/VBInputField";
import VBSelect from "../../../Components/Utils/VBSelect";
import VBDatePiker from "../../../Components/Utils/VBDatePiker";
import Transactions from "./Components/Transactions";
import Address from "./Components/Address";
import BankDetails from "./Components/BankDetails";
import { callCreateSupplier } from "../../../Api/Contacts/contacts";
import { openSnackbar } from "../../../features/SnackBarslice";
import { jsObjtoFormData } from "../../../generalFunction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CreateSupplier() {
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [uploadDetail, setUploadDetail] = useState({
    IsActive: true,
    CustomerName: "",
    CustomerCode: "",
    DisplayName: "",
    OpeningBalance: "",
    PartyImage: null,
    PartyType: 2,
    CrOrDr: "DR",
    WorkPhone: "",
    PartyCode: "",
    WebURL: "",
    Email: "",
    as_on_date: moment(),
    creditPeriod: "",
    PanNumber: "",
    creditLimit: "",
    CRNo: "",
    priceCategory: null,
    routes: null,
    VATNumber: "",
    GSTNumber: "",
    GST_Treatment: "",
    VAT_Treatment: "",
    treatment: null,
    AddresList: [],
    bankList: [],
  });
  const [value, setValue] = React.useState(0);
  const [showImage, setShowImage] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddImage = function () {
    let btn = document.getElementById("imgInput");
    btn.click();
  };

  const handleImage = function (e) {
    let ing = e.target?.files[0];
    if (ing) {
     setShowImage(URL.createObjectURL(ing));
      setUploadDetail({
        ...uploadDetail,
        PartyImage: ing,
      }); 
    }
  };

  const handleSubmitSupplier = function () {
    // document.getElementById("formOneId").click();
    // document.getElementById("formTwoId").click();
    // Reference to both forms
    const formOne = document.getElementById("FormOne");
    const formTwo = document.getElementById("FormTwo");

    // Function to handle the first form submission
    const handleFormOneSubmit = (e) => {
      e.preventDefault(); 
      formTwo.addEventListener("submit", handleFormTwoSubmit, { once: true }); // Ensure it only runs once
      formTwo.requestSubmit(); // Programmatically submit formTwo
    };

    // Function to handle the second form submission
    const handleFormTwoSubmit = (e) => {
      e.preventDefault();
      createCustomer(); // Call createCustomer() after formTwo submission
    };

    formOne.addEventListener("submit", handleFormOneSubmit, { once: true }); // Ensure it only runs once
    formOne.requestSubmit();

  };

  const createCustomer = async function () {
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,

      CustomerName: uploadDetail.CustomerName,
      DisplayName: uploadDetail.DisplayName,
      OpeningBalance: uploadDetail.OpeningBalance,
      PartyType: uploadDetail.PartyType,
      CrOrDr: uploadDetail.CrOrDr,
      WorkPhone: uploadDetail.WorkPhone,
      PartyCode: uploadDetail.PartyCode,
      CreditLimit: uploadDetail.creditLimit,
      CreditPeriod: uploadDetail.creditPeriod,
      priceCategory: uploadDetail.priceCategory.id,
      Routes: uploadDetail.routes.id,
      WebURL: uploadDetail.WebURL,
      Email: uploadDetail.Email,
      CRNo: uploadDetail.CRNo,
      PanNumber: uploadDetail.PanNumber,
      as_on_date: uploadDetail.as_on_date.format("YYYY-MM-DD"),
      GST_Treatment: uploadDetail.GST_Treatment,
      VAT_Treatment: uploadDetail.VAT_Treatment,
      Address: uploadDetail.AddresList,
      Bank: uploadDetail.bankList,
    };
    
    console.log(payload);

    const formData = jsObjtoFormData(payload);

    const res = await callCreateSupplier(formData, dispatch);
    if (res.data.StatusCode === 6000) {
      console.log(res);
      dispatch(
        openSnackbar({
          open: true,
          message: res.data?.message || "Error Occurred In API",
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: res.data?.message || "Error Occurred In API",
          severity: "error",
        })
      );
    }
  };

  // useEffect(() => {
  //   const formOne = document.getElementById("FormOne");
  //   const formTwo = document.getElementById("FormTwo");
  //   formOne.addEventListener('submit', function(e) {
  //     e.preventDefault();
  //   });

  //   formTwo.addEventListener('submit', function(e) {
  //     e.preventDefault();
  //   });
  //   return () => {
  //     formOne.removeEventListener('submit');
  //     formTwo.removeEventListener('submit');
  //   };
  // }, [])

  return (
    <Paper sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: "1px solid",
          p: 3,
          borderColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", fontWeight: "600", lineHeight: "36px" }}
        >
          Create Supplier
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            sx={{ fontSize: "16px", textTransform: "none", px: 2 }}
            variant="text"
          >
            Cancel
          </Button>
          <BlueButton sx={{ px: 4 }} onClick={() => handleSubmitSupplier()}>
            Save
          </BlueButton>
        </Box>
      </Box>

      <form id="FormOne">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 8,
            py: 2,
            mb: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: 6 }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#5C5C5C",
                  mb: 4,
                }}
              >
                Profile image
              </Typography>
              <Badge sx={{
                vertical: 'top',
                horizontal: 'right',
              }} badgeContent={showImage && <IconButton onClick={() => setShowImage(null)} ><DeleteIcon /></IconButton>}>
                <ImageButton
                  onClick={() => {
                    handleAddImage();
                  }}
                >
                  {showImage ? (
                    <Avatar
                      alt="Remy Sharp"
                      src={showImage}
                      sx={{ height: "100%", width: "100%" }}
                    />
                  ) : (
                    <AddPhotoAlternateIcon />
                  )}
                </ImageButton>
              </Badge>

              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                id="imgInput"
                onChange={(e) => handleImage(e)}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <VBInputField
                label="Supplier Name"
                type="text"
                required
                placeholder="Type here"
                value={uploadDetail.CustomerName}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    CustomerName: e.target.value,
                  });
                }}
              />
              {/* <Box sx={{ mb: 2, minWidth: 350 }} /> */}
              <VBInputField
                label="Supplier Code"
                placeholder="Type here"
                type="number"
                required
                value={uploadDetail.CustomerCode}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    CustomerCode: e.target.value,
                  });
                }}
              />
              <VBInputField
                label="Display Name"
                placeholder="Type here"
                value={uploadDetail.DisplayName || uploadDetail.CustomerName}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    DisplayName: e.target.value,
                  });
                }}
              />
            </Box>
          </Box>

          <VBSwitch
            label={"Active"}
            labelPlacement="start"
            labelColor={"black"}
            checked={uploadDetail.IsActive}
            onChange={() => {
              setUploadDetail({
                ...uploadDetail,
                IsActive: !uploadDetail.IsActive,
              });
            }}
          />
        </Box>

        <Box sx={{ flexGrow: 1, minWidth: 1000, px: 8, mb: 2 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 4, md: 3 }}
          >
            <Grid item xs={2}>
              <VBInputField
                label="Opening Balance"
                placeholder="Type here"
                type="number"
                value={uploadDetail.OpeningBalance}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    OpeningBalance: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  pt: "18px",
                }}
              >
                <div style={{ width: "35%" }}>
                  <VBSelect
                    options={["DR", "CR"]}
                    value={uploadDetail.CrOrDr}
                    handleChange={(e) => {
                      setUploadDetail({
                        ...uploadDetail,
                        CrOrDr: e.target.value,
                      });
                    }}
                  />
                </div>
                <VBDatePiker
                  notLabel
                  fromDate={uploadDetail.as_on_date}
                  fromOnChange={(e) =>
                    setUploadDetail({
                      ...uploadDetail,
                      as_on_date: e,
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <VBInputField
                label="Work Phone"
                placeholder="Type here"
                type="number"
                value={uploadDetail.WorkPhone}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    WorkPhone: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ px: 8, mb: 5 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 4, md: 3 }}
          >
            <Grid item xs={3}>
              <VBInputField
                label="Email ID"
                type="email"
                placeholder="Type here"
                value={uploadDetail.Email}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    Email: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <VBInputField
                label="Website URL"
                type="url"
                value={uploadDetail.WebURL}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    WebURL: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </form>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Transactions"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Address"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(1)}
          />
          <Tab
            label="Bank Details"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Box sx={{ px: 8 }}>
        <CustomTabPanel value={value} index={0}>
          <Transactions
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Address
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <BankDetails
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
          />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
}

export default CreateSupplier;

const ImageButton = styled(IconButton)(() => ({
  backgroundColor: "#E8E8E8",
  fontSize: "35px",
  padding: 0,
  height: "112px",
  width: "112px",
}));

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));
